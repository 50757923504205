import React, { useState } from "react";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers";

function DatePicker({ handleDateChange }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StaticDatePicker
        defaultValue={dayjs(new Date())}
        disableToolbar
        isLandscape={false}
        onChange={handleDateChange}
      />
    </LocalizationProvider>
  );
}

export default DatePicker;
